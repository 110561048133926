import axios from "axios"

axios.defaults.baseURL = process.env.NODE_ENV === "production" ? "" : __host
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded"
const Xhr = axios.create({
	timeout: 15000
})

Xhr.interceptors.request.use(
	config => {
		if (!config.noLoading) {
			window.$showLoading()
		}
		config.baseURL = process.env.NODE_ENV === "production" || config.noHost ? "" : __host
		return config
	},
	error => {
		return Promise.reject(error)
	}
)

Xhr.interceptors.response.use(
	response => {
		if (!response.config.noLoading) {
			window.$hideLoading()
		}
		const res = response.data
		if (!response.config.isUpload && res.code != "0001") {
			if (!response.config.noToastErr) {
				window.$toast(res.message || "请求错误", "error")
			}
			return Promise.reject(res || "error")
		} else {
			return res.data ? res.data : res
		}
	},
	error => {
		window.$hideLoading && window.$hideLoading()
		if (!error.response || !error.response.config.noToastErr) {
			window.$toast(error.message || "请求失败", "error")
		}
	}
)

export default Xhr
