var HashMap = function() {
	this.Constant_LEN = 31
	this.hashArray = new Array(this.Constant_LEN)
	this._allValues = null
}

HashMap.prototype = {
	set: function(key, value) {
		var arrVal = this.getHashedArr(key),
			valIndex = this._isContainedKey(arrVal, key)

		if (valIndex == -1) {
			arrVal.push({
				key: key,
				value: value
			})
			this._allValues && this._allValues.push(value)
		} else {
			if (typeof value == "object") {
				if (Array.isArray(value)) {
					arrVal[valIndex].value = value
				} else {
					Object.assign(arrVal[valIndex].value, value)
				}
			} else {
				arrVal[valIndex].value = value
			}
			this._allValues = null
		}
	},

	get: function(key) {
		var arrVal = this.getHashedArr(key),
			valIndex = this._isContainedKey(arrVal, key)

		if (valIndex == -1) {
			return null
		} else {
			return arrVal[valIndex].value
		}
	},

	getHashedArr: function(key) {
		var hashKey = this._hashKey(key),
			arrVal = this.hashArray[hashKey]

		if (this._isEmpty(arrVal)) {
			this.hashArray[hashKey] = []
			return this.hashArray[hashKey]
		}
		return arrVal
	},

	remove: function(key) {
		var hashKey = this._hashKey(key),
			arrVal = this.hashArray[hashKey],
			valIndex = this._isContainedKey(arrVal, key)

		if (valIndex == -1) {
			return false
		} else {
			arrVal.splice(valIndex, 1)
			this._allValues = null
			return true
		}
	},

	isContainedKey: function(key) {
		var hashKey = this._hashKey(key),
			arrVal = this.hashArray[hashKey],
			valIndex = this._isContainedKey(arrVal, key)

		if (valIndex == -1) {
			return false
		} else {
			return true
		}
	},

	getAllValue: function() {
		var hashArr = this.hashArray,
			_allValues = [],
			itemArr = null

		if (this._allValues == null) {
			for (var j = hashArr.length - 0; j >= 0; j--) {
				if (this._isEmpty(hashArr[j])) continue
				itemArr = hashArr[j]
				for (var k = itemArr.length - 1; k >= 0; k--) {
					_allValues.push(itemArr[k].value)
				}
			}
			this._allValues = _allValues
		}
		return this._allValues
	},

	_hashKey: function(key) {
		if (window.dbId != undefined) {
			key = "" + window.dbId + key
		}
		var parsedKey = parseInt(key),
			hashIndex = 0

		if (isNaN(parsedKey)) {
			parsedKey = this._parseAnthorKey(key)
		}
		hashIndex = parsedKey % this.Constant_LEN
		return hashIndex
	},

	_isEmpty: function(cell) {
		if (cell === null || cell === undefined) return true
		return false
	},

	_isContainedKey: function(arr, key) {
		if (this._isEmpty(arr)) return -1
		for (var j = arr.length - 1; j >= 0; j--) {
			if (arr[j].key === key) return j
		}
		return -1
	},

	_parseAnthorKey: function(key) {
		var keyCodeTotal = 0

		for (var i = 0, len = key.length; i < len; i++) {
			keyCodeTotal = keyCodeTotal + key.charCodeAt(i)
		}
		return keyCodeTotal
	}
}

window.CachCommonHashMap = new HashMap()

var permission = {
	tempUser: parent.WUser || {},

	initMPToCache: function() {
		var that = this,
			md = null,
			postData = { roleId: that.tempUser.roleId || "1" }

		postData = (function(obj) {
			// 转成post需要的字符串.
			var str = ""

			for (var prop in obj) {
				str += prop + "=" + obj[prop] + "&"
			}
			return str
		})(postData)

		var xhr = new XMLHttpRequest(),
			url = __host + "/pt/queryRoleOperateList"
		xhr.open("POST", url, false)
		xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded")

		xhr.onreadystatechange = function() {
			var XMLHttpReq = xhr
			if (XMLHttpReq.readyState == 4) {
				if (XMLHttpReq.status == 200) {
					var text = XMLHttpReq.responseText,
						data = JSON.parse(text).data
					md = that.formatMP(data)
					that.setUserIdMPRight(md)
				}
			}
		}
		xhr.send(postData)
		return md
	},

	setUserIdMPRight: function(d) {
		this.setUserIdRight("mangerPermission_", d)
	},

	getUserIdRight: function(key, userId) {
		return CachCommonHashMap.get(key + userId)
	},

	setUserIdRight: function(key, d) {
		var userId = this.tempUser.userId || "40"
		CachCommonHashMap.set(key + userId, d)
	},

	removeUserIdRight: function(key, userId) {
		CachCommonHashMap.remove(key + userId)
	},

	removeUserIdMPRight: function(userId) {
		this.removeUserIdRight("mangerPermission_", userId)
	},

	getUPOperationRight: function(module, operation) {
		var userId = this.tempUser.userId || "40",
			userMPRight = this.getUPModuleRight(userId, module),
			isHasOperation = false
		if (userMPRight && userMPRight[operation]) {
			var powers = userMPRight[operation]
			if (powers) {
				isHasOperation = true
			}
		}
		return isHasOperation
	},

	isContainInArray: function(arr, value) {
		if (arr && arr.length) {
			for (var i = 0; i < arr.length; i++) {
				if (arr[i] == value) {
					return true
				}
			}
		}
		return false
	},

	getUserIdMPRight: function(userId) {
		var m = this.getUserIdRight("mangerPermission_", userId)
		if (m === null) {
			m = this.initMPToCache(userId)
		}
		return m || {}
	},

	getUPModuleRight: function(userId, module) {
		var userIdUPRight = this.getUserIdMPRight(userId)
		return userIdUPRight[module] || null
	},

	formatMP: function(d) {
		var o = {},
			k,
			m
		d = d || []
		for (var i = 0; i < d.length; i++) {
			k = d[i]["modelCode"]
			o[k] = o[k] || {}
			if (d[i]["list"] && Array.isArray(d[i]["list"])) {
				var tempList = d[i]["list"] || []
				for (var j = 0; j < tempList.length; j++) {
					var tempObj = tempList[j]
					m = tempObj["operateCode"]
					o[k][m] = tempObj
				}
			}
		}
		return o
	}
}

export default permission
