<template>
	<vxe-modal v-model="showModal" :title="title" :width="width" :position="position" resize destroy-on-close @show="modalOpen" @hide="modalClose">
		<slot></slot>
	</vxe-modal>
</template>

<style></style>

<script type="text/babel">
export default {
	name: "modal",
	props: {
		value: Boolean,
		title: {
			type: String,
			default: ""
		},
		position: {
			type: Object,
			default: { top: 150 }
		},
		width: {
			type: String,
			default: "800"
		}
	},
	data() {
		return {
			showModal: false
		}
	},
	watch: {
		value(open) {
			this.showModal = open
		}
	},
	methods: {
		modalClose() {
			this.showModal = false
			this.$emit("input", false)
		},
		modalOpen() {}
	}
}
</script>
