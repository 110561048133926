<template>
	<el-aside>我是公共aside</el-aside>
</template>
<script>
export default {
	name: "y-aside",
	data: function() {
		return {}
	},
	props: [],
	methods: {},
	created: function() {}
}
</script>
