import persistedState from "vuex-persistedstate"

export default {
	state: {
		tabSelected: 0,
		isFVsInited: false,
		isSysParamsInited: false,
		isTenantConfigInited: false,
		isUserInited: false,
		login: false,
		FVs: {},
		ossPrefix: "",
		ossConfig: {},
		tenantConfig: {},
		user: {}
	},
	getters: {
		tabSelected: state => state.tabSelected,
		FVs: state => state.FVs,
		ossConfig: state => state.ossConfig,
		ossPrefix: state => state.ossPrefix,
		city: state => state.city, // 用户当前选中的城市数据
		localCity: state => state.localCity, // 当前定位城市名称
		defaultCity: state => state.defaultCity, // cms城市管理设置的默认城市
		user: state => state.user,
		login: state => state.login,
		tenantConfig: state => state.tenantConfig
	},
	mutations: {
		updateTabSelected(state, selected) {
			state.tabSelected = selected
		},
		updateFVs(state, FVs) {
			state.FVs = FVs
			state.isFVsInited = true
		},
		updateOssConfig(state, ossConfig) {
			state.ossConfig = ossConfig
			if (ossConfig) {
				window.ossPrefix = ossConfig.ossCDN || ossConfig.ossEndpoint
				state.ossPrefix = window.ossPrefix
				state.ossEndpoint = ossConfig.ossEndpoint
				state.isSysParamsInited = true
			}
			console.log("state.ossPrefix:", state.ossPrefix)
		},
		updateTenantConfig(state, tenantConfig) {
			state.tenantConfig = tenantConfig
			state.isTenantConfigInited = true
		},
		updateLoginStatus(state, loginStatus) {
			state.login = loginStatus
			if (!loginStatus) {
				state.user = {}
			}
		},
		updateUser(state, user) {
			state.user = user
			state.isUserInited = true
		}
	},
	actions: {
		refreshFVs({ commit }) {
			// 获取值集
			let parms = window.$FVParams
			window
				.$Xhr({
					url: window.$basePath + "/merchantsettle/api/common/getFlexValuesByCodes",
					method: "post",
					data: {
						codes: parms
					},
					noLoading: true,
					noToastErr: true
				})
				.then(FV => {
					commit("updateFVs", FV)
				})
		},
		refreshTenantConfig({ commit }) {
			// 商户参数配置
			window
				.$Xhr({
					url: window.$basePath + "/mobile/merchant/sysConfig/qryAllAppConfig",
					noLoading: true,
					noToastErr: true
				})
				.then(res => {
					commit("updateTenantConfig", res)
				})
		},
		refreshOssConfig({ commit }) {
			// 阿里云参数配置
			window
				.$Xhr({
					url: window.$basePath + "/merchantsettle/common/getSysConfigs",
					noLoading: true,
					noToastErr: true
				})
				.then(res => {
					commit("updateOssConfig", res)
				})
		},
		refreshUser({ commit }) {
			window
				.$Xhr({
					url: window.$basePath + "/userLogin/getUserInfo",
					noLoading: true,
					noToastErr: true
				})
				.then(res => {
					if (res && res.id) {
						commit("updateUser", res)
						commit("updateLoginStatus", true)
					} else {
						commit("updateLoginStatus", false)
					}
				})
		}
	},
	plugins: [persistedState()]
}
