import Loadingvue from "./loading.vue"


const Loading = {}
Loading.install = function(Vue) {
	const LoadingConstructor = Vue.extend(Loadingvue)
	let instance = new LoadingConstructor()
	// 挂载
	instance.$mount(instance.$el)
	document.body.appendChild(instance.$el)

	// 让所有实例共享这个方法
	Vue.prototype.$showLoading = () => {
		instance.show = true;
	}

	Vue.prototype.$hideLoading = () => {
		instance.show = false;
	}
}
export default Loading
