import Vue from "vue"
import Xhr from "./lib/xhr"
import App from "./App"
import store from "./store/pc"
import Element from "element-ui"
// eslint-disable-next-line no-unused-vars
import XEUtils from "xe-utils"
import VXETable from "vxe-table"

import router from "./router"
import Loading from "@/plugins/loading"

import Modal from "@/components/ui/Modal.vue"
import Header from "@/components/Header.vue"
import Aside from "@/components/Aside.vue"
import Footer from "@/components/Footer.vue"

import mixin from "./lib/mixin"
import gridFormats from "./lib/grid-formats"

import "vxe-table/lib/style.min.css"
import "@/style/index.scss"

// 引入Element和VXETable
Vue.use(Element)
VXETable.formats.mixin(gridFormats)
Vue.use(VXETable)

//公用filter/方法/变量 全局mixin须在自定义插件、组件之前
Vue.mixin(mixin)

//公用自定义插件
Vue.use(Loading)

//全局公用组件
Vue.component(Modal.name, Modal)
Vue.component(Header.name, Header)
Vue.component(Aside.name, Aside)
Vue.component(Footer.name, Footer)

//挂在全局的变量、方法
window.$Xhr = Xhr
window.$basePath = ""
window.$FVParams = "10000027,800015,20210420004,202209230001"
window.$store = store

store.dispatch("refreshFVs")
store.dispatch("refreshOssConfig")
    // store.dispatch("refreshTenantConfig")
store.dispatch("refreshUser")

Vue.prototype.$Xhr = window.$Xhr
Vue.prototype.$basePath = window.$basePath

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app")