<template>
	<div id="app">
		<router-view :key="key" />
	</div>
</template>

<script>
export default {
	name: "App",
	computed: {
		key() {
			//解决在同一页面跳转仅仅参数刷新时页面不刷新的问题
			return this.$route.name !== undefined ? this.$route.name + new Date() : this.$route + new Date()
		}
	},
	watch: {
		$route(to, from) {
			document.title = to.meta.title
		}
	}
}
</script>
