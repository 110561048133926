<template>
	<div class="loading" v-show="show">
		<div id="wu-full-screen" style="position:fixed;left:0;top:0;right:0;bottom:0;" class="full-screen-overlay"></div>
		<div style="position:fixed;left:0;top:0;right:0;bottom:0;" class="loading-l"></div>
	</div>
</template>

<style></style>

<script type="text/babel">
export default {
	data() {
		return {
			show: false
		}
	}
}
</script>
