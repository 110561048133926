import XEUtils from "xe-utils"
import { FV } from "@/lib/tools"

/**
 * 表格内常用渲染格式
 */
export default {
	/**
	 * 性别
	 */
	formatSex({ cellValue }) {
		return cellValue ? (cellValue === "1" ? "男" : "女") : ""
	},

	/**
	 * 是否
	 */
	formatIs({ cellValue }) {
		return cellValue != undefined ? (cellValue === "1" || cellValue === true ? "是" : "否") : ""
	},

	/**
	 * 下拉选项
	 */
	formatSelect({ cellValue }, list) {
		const item = list.find(item => item.value === cellValue)
		return item ? item.label : ""
	},

	/**
	 * 隐藏中间四位手机号
	 */
	mobileHide({ cellValue }) {
		var arr = cellValue.toString().split("")
		arr.splice(3, 4, "****")
		return arr.join("")
	},

	/**
	 * 值集
	 */
	FV({ cellValue }, code, FVs) {
		return FV(cellValue, code, FVs)
	},

	/**
	 * 日期，默认 yyyy-MM-dd HH:mm:ss
	 */
	formatDate({ cellValue }, format) {
		if (format) {
			format = format.replace(/h/g, "H")
		}
		return XEUtils.toDateString(cellValue, format || "yyyy-MM-dd")
	},

	/**
	 * 格式金额，默认2位数
	 */
	formatAmount({ cellValue }, digits) {
		return XEUtils.commafy(cellValue, { digits: digits || 2 })
	},

	/**
	 * 银行卡，默认每4位隔开
	 */
	formatBankcard({ cellValue }) {
		return XEUtils.commafy(cellValue, { spaceNumber: 4, separator: " " })
	},

	/**
	 * 四舍五入,默认两位数
	 */
	formatFixedNumber({ cellValue }, digits) {
		return XEUtils.toNumber(cellValue).toFixed(digits || 2)
	},

	/**
	 * 截取小数,默认两位数
	 */
	formatCutNumber({ cellValue }, digits) {
		return XEUtils.toFixedString(cellValue, digits || 2)
	},

	/**
	 * 转换 moment 类型为字符串
	 */
	toMomentString({ cellValue }, format) {
		return cellValue ? cellValue.format(format) : ""
	}
}
