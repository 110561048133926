import Vue from "vue"
import Router from "vue-router"
import mobileMap from "./mobile"
import pcMap from "./pc"
import { isMobile } from "@/lib/tools"

const login = () => import("@/pages/login")
const register = () => import("@/pages/register")
const success = () => import("@/pages/success")
const initialSetting = () => import("@/pages/pc/initialSetting")
const index = () => import("@/pages/index")

Vue.use(Router)
let routes = [
	{ path: "/login", component: login, name: "login", meta: { title: "登录" } },
	{ path: "/register", component: register, name: "register", meta: { title: "注册" } },
	{ path: "/index", component: index, name: "index", meta: { title: "首页", needLogin: true } },
	{ path: "/initialSetting", component: initialSetting, name: "initialSetting", meta: { title: "入驻配置" } },
	{ path: "/success", component: success, name: "success", meta: { title: "成功" } },
	{ path: "/", redirect: "/index" }
]
routes = routes.concat(isMobile() ? mobileMap.routes : pcMap.routes)

var router = new Router({
	routes: routes
})

router.beforeEach((to, from, next) => {
	//全局钩子函数
	to.matched.some(route => {
		if (route.meta.needLogin) {
			// 拦截登录
			if (window.$store.state.login) {
				next()
			} else {
				next({ name: "login", query: { redirect: route.path } })
			}
		} else {
			next()
		}
	})
})

export default router
