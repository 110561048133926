<template>
	<el-header class="header">
		<div class="header-cont">
			<div>
				<a class="logo-cont" href="http://www.yozuyun.com/">
					<img src="https://image.homeplus.cn/cms/11ff2334c2f74b7c83431a092c2da870.webp" class="head-logo" />
				</a>
			</div>
			<div class="menu-cont">
				<span class="exit-btn" @click="logout" v-if="show">
					<i class="icon-exit mg-r5"></i>
					<span>退出</span>
				</span>
				<!--<i class="split-line" v-if="show">|</i>
				<span href="tel://0755-36909510">
					<i class="icon-tel mg-r5"></i>
					<span>0755-36909510</span>
				</span>-->
			</div>
		</div>
	</el-header>
</template>
<script>
export default {
	name: "y-header",
	data: function() {
		return {
			show: true
		}
	},
	props: [],
	methods: {
		logout() {
			this.$confirm("确认退出登录吗？", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {
					this.$Xhr({
						url: `/userLogin/loginOut`
					})
						.then(() => {
							this.$store.commit("updateLoginStatus", false)
							if (this.$route.name != "index") {
								this.go({ name: "index" })
							} else {
								this.go({ name: "login", query: { redirect: "/index" } })
							}
						})
						.catch(() => {
							this.loginFlag = false
						})
				})
				.catch(() => {})
		}
	},
	created: function() {
		if (this.$route.name == "register") {
			this.show = false
		}
	}
}
</script>
<style scoped lang="scss">
.header {
	@media screen and (max-width: 1024px) {
		display: none;
	}
	background: #fff;
	border-bottom: 1px solid #efefef;
	padding: 0;
}
.header-cont {
	width: 114rem;
	height: 100%;
	margin: 0 auto;
	display: flex;
}
.logo-cont {
	display: flex;
	height: 100%;
	align-items: center;
}
.split-line {
	color: #e8e8e8;
	margin: 0 2rem;
}
.exit-btn {
	cursor: pointer;
	&:hover {
		opacity: 0.68;
	}
}
.menu-cont {
	line-height: 6rem;
	flex: 1;
	align-items: flex-end;
	text-align: right;
}
</style>
