import Vue from "vue"
import UUID from "./uuid"
import permission from "./permission"
import Xhr from "./xhr"

/**
 * 获取用户信息
 * @return {Object} 用户信息
 */
const getUser = () => {
	var userObj = parent.WUser || pageUser || {}
	return userObj
}

/**
 * 获取页面参数
 * @param  {String} url location.href
 * @return {Object}     query
 */
const getPageQuery = url => {
	url = url || location.href
	var query = url.split("?")[1],
		reg = /([^=&\s]+)[=\s]*([^=&\s]*)/g,
		obj = {}
	while (reg.exec(query)) {
		if (RegExp.$1 !== "" && RegExp.$1 !== "undefined") {
			obj[RegExp.$1] = RegExp.$2
		}
	}
	return obj
}

/**
 * 获取当前日期
 * @param  {String} dateformat 日期格式，如'yyyy-MM-dd'
 * @return {String}            当前日期字符串
 */
const getCurrentDate = dateformat => {
	var self = this
	return self.formatDate(new Date().getTime(), dateformat || "yyyy-MM-dd")
}

/**
 * 日期格式化
 * @param  {Date/Number/String} date  日期对象/时间戳/日期字符串
 * @param  {String} dateformat 				日期格式
 * @return {String}            				日期字符串
 */
const formatDate = (date, dateformat) => {
	if (!date) {
		return ""
	}
	if (typeof date !== "number" && typeof date == "string" && (date.indexOf("-") > -1 || date.indexOf("/") > -1)) {
		return date
	}
	if (!dateformat) {
		dateformat = "yyyy-MM-dd"
	}
	date = new Date(date)
	var format = dateformat,
		o = {
			"M+": date.getMonth() + 1,
			"d+": date.getDate(),
			"h+": date.getHours(),
			"m+": date.getMinutes(),
			"s+": date.getSeconds(),
			"q+": Math.floor((date.getMonth() + 3) / 3),
			S: date.getMilliseconds()
		}
	if (/(y+)/.test(format)) {
		format = format.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length))
	}
	for (var k in o) {
		if (new RegExp("(" + k + ")").test(format)) {
			format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length))
		}
	}
	return format
}

/**
 * 渲染值集
 * @param  {String} code  code值，如'01'
 * @param  {String} name  对应值集名称，如'80005'
 * @return {String}       code对应的中文描述
 */
const FV = (code, name, FVs) => {
	if (!FVs && !Vue.prototype.FVs) {
		return ""
	}
	var FV = FVs ? FVs[name] : Vue.prototype.FVs[name],
		result = null
	FV &&
		FV.forEach(item => {
			if (item.code == code) {
				result = item.name
			}
		})
	return result
}

/**
 * 判断值集code是否开启拓展属性ext
 * @param  {String} name  对应值集名称，如'80005'
 * @param  {String} code  code值
 * @param  {String} ext   拓展属性值，如'ext04'
 * @return {Boolean}      当前属性是否开启
 */
const hasExt = (name, code, ext) => {
	var FV = Vue.prototype.FVs[name],
		exist = false
	FV &&
		FV.forEach(item => {
			if (item.code == code) {
				exist = item[ext] == "on" ? true : false
			}
		})
	return exist
}

/**
 * 异步添加值集（用于公用值集可配置组件）
 * @param  {String} codes  对应值集参数
 * @return {Boolean}      当前属性是否开启
 */
const getFVsAsync = codes => {
	new Xhr({
		url: "/common/getFlexValuesByCodes",
		data: {
			codes: codes
		},
		method: "post"
	}).then(data => {
		for (var key in data) {
			if (!Vue.prototype.FVs[key]) {
				Vue.prototype.FVs[key] = data[key]
			}
		}
	})
}
/**
 * 下载文件
 * @param  {String} key 阿里云key
 */
const download = key => {
	try {
		var elemIF = document.createElement("iframe")
		elemIF.src = "/attatchment/downloadImage?key=" + key
		elemIF.style.display = "none"
		document.body.appendChild(elemIF)
	} catch (e) {
		console.log("failed: download")
	}
}

/**
 * 阿里云图片压缩并自动补全
 * @param  {String} key    阿里云key
 * @param  {String} width  目标压缩宽度
 * @param  {String} height 目标压缩高度
 * @param  {String} type   压缩方式
 * @return {String}        压缩补全后的完整地址
 */
const getOssUploadData = (key, ossPrefix, width, height, type) => {
	if (!key || !ossPrefix) {
		return ""
	}
	let protocol = window.location.protocol || "http:",
		hostStr = ossPrefix.split("//")[1],
		IMGAGE_HOST = protocol + `//${hostStr}`

	var url = "",
		mtype = type || "m_lfit",
		mheight = height || 0,
		mwidth = width || 0
	if (key) {
		if (key.indexOf("http://") == 0 || key.indexOf("https://") == 0) {
			url = key
		} else {
			url = IMGAGE_HOST + key
		}
		if (mheight || mwidth) {
			url += "?x-oss-process=image/resize"
			url += "," + mtype
			mwidth && (url += ",w_" + mwidth)
			mheight && (url += ",h_" + mheight)
		}
	}
	return url
}

/**
 * 是否存在操作权限
 * @param  {String} module  模块名称
 * @param  {String} operate 操作名称
 * @return {Boolean}        是否有权限
 */
const hasPermission = (module, operate) => {
	return permission.getUPOperationRight(module, operate)
}

/**
 * 校验证件号码
 * @param  {String} idType 证件类型
 * @param  {String} idcard 证件号码
 * @return {Boolean}       是否通过校验
 */
const validateCard = (idType, idcard) => {
	let reg
	if (idType == "0") {
		var area = {
			11: "北京",
			12: "天津",
			13: "河北",
			14: "山西",
			15: "内蒙古",
			21: "辽宁",
			22: "吉林",
			23: "黑龙江",
			31: "上海",
			32: "江苏",
			33: "浙江",
			34: "安徽",
			35: "福建",
			36: "江西",
			37: "山东",
			41: "河南",
			42: "湖北",
			43: "湖南",
			44: "广东",
			45: "广西",
			46: "海南",
			50: "重庆",
			51: "四川",
			52: "贵州",
			53: "云南",
			54: "西藏",
			61: "陕西",
			62: "甘肃",
			63: "青海",
			64: "宁夏",
			65: "xinjiang",
			71: "台湾",
			81: "香港",
			82: "澳门",
			91: "国外"
		}
		if (area[parseInt(idcard.substr(0, 2))] == null) {
			return false
		}
		switch (idcard.length) {
			case 15:
				if ((parseInt(idcard.substr(6, 2)) + 1900) % 4 == 0 || ((parseInt(idcard.substr(6, 2)) + 1900) % 100 == 0 && (parseInt(idcard.substr(6, 2)) + 1900) % 4 == 0)) {
					reg = /^[1-9][0-9]{5}[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}$/ //测试出生日期的合法性
				} else {
					reg = /^[1-9][0-9]{5}[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))[0-9]{3}$/ //测试出生日期的合法性
				}
				if (reg.test(idcard)) {
					return true
				} else {
					return false
				}
			case 18:
				if (parseInt(idcard.substr(6, 4)) % 4 == 0 || (parseInt(idcard.substr(6, 4)) % 100 == 0 && parseInt(idcard.substr(6, 4)) % 4 == 0)) {
					reg = /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}[0-9Xx]$/ //闰年出生日期的合法性正则表达式
				} else {
					reg = /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))[0-9]{3}[0-9Xx]$/ //平年出生日期的合法性正则表达式
				}
				if (reg.test(idcard)) {
					return true
				} else {
					return false
				}
			default:
				return false
		}
	} else if (idType == "1") {
		reg = /^[Hh]{1}([0-9]{10}|[0-9]{8})$/
		return reg.test(idcard)
	} else if (idType == "2") {
		reg = /^[Mm]{1}([0-9]{10}|[0-9]{8})$/
		return reg.test(idcard)
	} else if (idType == "3") {
		reg = /^[0-9]{10}|[0-9]{8}$/
		return reg.test(idcard)
	} else if (idType == "4") {
		reg = /[a-zA-Z\d]{1,25}$/
		return reg.test(idcard)
	}
	return true
}

/**
 * 单文件web直传
 * @param  {FormData} file  文件数据
 * @param  {Object} options 上传配置，dir-上传目录（默认'homeplus'）
 * @return {Promise}
 */
const upload = (file, options) => {
	var fileName = file.name,
		subfixIndex = fileName.lastIndexOf("."),
		subfix = subfixIndex > -1 ? fileName.slice(subfixIndex + 1) : null
	subfix && subfix.toLowerCase()

	options = options || {}
	return new Promise((res, rej) => {
		new Xhr({
			url: "/aliyun/get?dir=" + (options.dir || "homeplus"),
			method: "get"
		})
			.then(data => {
				var obj = data,
					config = {}
				config.host = obj["host"]
				config.policyBase64 = obj["policy"]
				config.accessid = obj["accessId"]
				config.signature = obj["signature"]
				config.expire = parseInt(obj["expire"])
				config.callbackbody = obj["callback"]
				config.dir = obj["dir"]
				var fd = new FormData(),
					uuid = new UUID().toString(),
					key = config.dir + uuid + (subfix ? "." + subfix : "")
				fd.append("key", key)
				fd.append("success_action_status", "200")
				fd.append("x-oss-object-acl", "public-read")
				fd.append("x-oss-meta-fullname", fileName)
				fd.append("OSSAccessKeyId", config.accessid)
				fd.append("policy", config.policyBase64)
				fd.append("signature", config.signature)
				fd.append("success_action_status", "200")
				fd.append("file", file)
				if (config.host.indexOf("http:") > -1) {
					var protocol = window.location.protocol || "http:",
						subUrl = config.host.substring(5, config.host.length)
					config.host = protocol + subUrl
				}
				new Xhr({
					url: data.host,
					method: "post",
					data: fd,
					headers: { "Content-Type": "multipart/form-data" },
					isUpload: true
				})
					.then(() => {
						var size = file.size > 1000000 ? parseFloat(file.size / 1000000).toFixed(2) + "M" : parseFloat(file.size / 1000).toFixed(2) + "KB"
						res({
							attachment: fileName,
							aliyunAddress: key,
							size: size
						})
					})
					.catch(e => {
						rej(e)
					})
			})
			.catch(e => {
				rej(e)
			})
	})
}

/**
 * 判断是否是移动端
 * @return {Boolean} 是否是移动端
 */
const isMobile = () => {
	var ua = navigator.userAgent,
		android = ua.match(/(Android);?[\s\/]+([\d.]+)?/),
		ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
		ipod = ua.match(/(iPod)(.*OS\s([\d_]+))?/),
		iphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/)
	// microMessenger = (iphone || ipad || ipod || android) && ua.match(/.*AppleWebKit(?=.*MicroMessenger)/i)
	return iphone || ipad || ipod || android
}

export { getUser, getPageQuery, getCurrentDate, formatDate, FV, hasExt, getFVsAsync, download, getOssUploadData, hasPermission, validateCard, upload, isMobile }
