import { getPageQuery, getOssUploadData, formatDate, FV } from "./tools"
import permission from "./permission"
import { isMobile } from "@/lib/tools"

const mixin = {
	data() {
		return {
			$query: {}, // 当前地址参数
			$staticUrl: `/public/`, // 静态资源路径
			$isMobile: false
		}
	},
	created() {
		let vueCliBaseUrl = process.env.BASE_URL || ""
		// vue脚手架中的静态图片必须用process.env.BASE_URL给变量做一次赋值后使用，直接在data中声明无效
		this.$staticUrl = vueCliBaseUrl || `/public/`
		this.$isMobile = isMobile()

		window.$toast = this.$toast
		window.$showLoading = this.$showLoading
		window.$hideLoading = this.$hideLoading

		this.$query = getPageQuery()
	},
	filters: {
		formatDate(date, dateformat) {
			return formatDate(date, dateformat)
		},
		imgPrefix(key, ossPrefix, width, height) {
			return getOssUploadData(key, ossPrefix, width, height)
		},
		FV(value, code) {
			return FV(value, code)
		},
		ellipsis(value, length) {
			length = length || 25
			return value && value.length > length ? value.substr(0, 25) + "..." : value
		}
	},
	methods: {
		/**
		 * UI公共方法
		 * 命名规范: $xxx
		 */
		$toast() {
			// 提示消息
			this.$message({ message: arguments[0], type: arguments[1] || "success" })
		},

		/**
		 * 业务公共方法
		 * 命名规范: 驼峰式命名
		 */
		imgPrefix(key, ossPrefix, width, height) {
			// 阿里云图片自动补全
			return getOssUploadData(key, ossPrefix, width, height)
		},
		FV(value, code) {
			// 获取值集
			return FV(value, code)
		},
		scrollInValid(result) {
			// 找到errMap中第一个元素滚动至视野中
			var $errEl
			if (result.errMap != {} && result.$form.$children.length) {
				for (var key in result.errMap) {
					if (!$errEl) {
						result.$form.$children.forEach(function(item) {
							if (item.field == key) {
								$errEl = item.$el
							}
						})
					}
				}
			}
			if ($errEl) {
				$errEl.scrollIntoView()
			}
		},
		hasPermission(module, operate) {
			// 操作权限是否存在
			return permission.getUPOperationRight(module, operate)
		},
		go(options) {
			// 跳转页面
			if (typeof options == "number") {
				this.$router.go(options)
			} else {
				this.$router.push(options)
			}
		}
	}
}
export default mixin
